import React from "react";
import WintercupTable from "./wintercupTable";
import { Stack } from "@mui/material";

const rules = [
  { title: "Putting:", text: "Autoput by hcp" },
  { title: "Pins:", text: "Easy" },
  { title: "Wind:", text: "Calm" },
  { title: "Fairway firmness:", text: "Medium" },
  { title: "Green firmness:", text: "Soft" },
  { title: "Stimp:", text: "8" },
];

const courses = [
  {
    name: "Sutton Bay",
    start: "2.12",
    end: "31.12",
    tee: "Sutton",
    completed: true,
  },
  {
    name: "Sebonack Golf Club",
    start: "06.01",
    end: "03.02",
    tee: "White",
    completed: true,
  },
  {
    name: "Le Touquet Resort",
    start: "10.02",
    end: "02.03",
    tee: "Blue",
    completed: true,
  },
  {
    name: "Royal Troon",
    start: "09.03",
    end: "06.04",
    tee: "Red",
    completed: true,
  },
  {
    name: "Finale: Lofoten Links",
    start: "13.04",
    end: "04.05",
    tee: "55",
    completed: true,
  },
];

const WintercupPage = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      pb={2}
      sx={{ maxWidth: "100vw" }}
    >
      <h1>Wintercup regler</h1>
      <p style={{ fontFamily: "cursive" }}>
        «Mamba gjør det som mamba kan. <br />
        Jage mus, slå baller i vann.
        <br /> Rundens score skal herved telle.
        <br /> Hvis ikke må jeg mine køller selge.»
      </p>
      <h3>Baner:</h3>
      {courses.map((course) => (
        <p key={course.name}>
          {course.completed ? (
            <s>
              {" "}
              <b>{course.name}:</b> Rundestart: {course.start}
              <br /> Frist: {course.end}
              <br /> <b>Tee:</b> {course.tee}
            </s>
          ) : (
            <p>
              <b>{course.name}:</b> Rundestart: {course.start}
              <br /> Frist: {course.end}
              <br /> <b>Tee:</b> {course.tee}
            </p>
          )}
        </p>
      ))}
      <h3>Instillinger:</h3>
      <p>
        Før runden:{" "}
        <u>Sjekk oppsettet av simulatoren og sørg for at det er korrekt.</u>
      </p>
      <p>
        Utslagssted: Se “Baner” Game type: Individual stroke (with hcp) (net)
      </p>
      {rules.map((rule) => (
        <p key={rule.title}>
          <b>{rule.title}</b> {rule.text}
        </p>
      ))}
      <h1>Resultater</h1>
      <WintercupTable />
    </Stack>
  );
};

export default WintercupPage;
