import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { getMatchingUser, makeGuess } from "../firebase";
import { mapGuess, getUserGuess } from "./helpers";
import { ALL_MAMBAS } from "./constants";
import { useMambas } from "../contexts/MambasContext";
import { CircularProgress, Stack, Button } from "@mui/material";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;
const MambaItem = styled.div`
  border: 1px solid grey;
  border-radius: 5px;
  margin-bottom: ${grid}px;
  background-color: rgb(253, 252, 244);
  color: black;
  padding: ${grid}px;
  width: 100%;
`;

const Mamba = ({ mamba, index }) => {
  const [mambaHCP, setMambaHCP] = useState(undefined);
  const { mambasStatistics: mambasWithStats } = useMambas();
  useEffect(() => {
    if (mambasWithStats) {
      const mambaStats = mambasWithStats.find((m) => m.name === mamba.name);
      if (mambaStats) {
        const latestHCP = mambaStats?.stats?.[0]?.hcp;
        if (latestHCP) {
          setMambaHCP(latestHCP);
        } else {
          setMambaHCP("NaN");
        }
      }
    }
  }, [mambasWithStats, mamba]);
  return (
    <Draggable draggableId={mamba.id} index={index}>
      {(provided) =>
        mamba ? (
          <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
            <MambaItem
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {index + 1 + ". " + mamba.name}
              {index + 1 === 1 ? " (Mambas Champion 2024)" : ""}
              {" - HCP: "} {mambaHCP ? mambaHCP : <CircularProgress />}
            </MambaItem>
          </Stack>
        ) : null
      }
    </Draggable>
  );
};

const MambaList = React.memo(function MambaList({ mambas }) {
  if (mambas === null) return null;
  return mambas.map((mamba, index) => {
    return <Mamba mamba={mamba} index={index} key={mamba.id} />;
  });
});

const MambaListApp = ({ mambasProphet, onChange }) => {
  const [mambas, setMambas] = useState(mambasProphet);

  useEffect(() => {
    setMambas(mambasProphet);
  }, [mambasProphet]);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedMambas = reorder(
      mambas,
      result.source.index,
      result.destination.index
    );

    setMambas(reorderedMambas);
    onChange(reorderedMambas);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {mambas ? <MambaList mambas={mambas} /> : null}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export const Profeten = () => {
  const { username } = useParams("user");
  const [user, setUser] = useState(undefined);

  const [mambas, changeMambas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true);
  const [status, setStatus] = useState("Leverer...");

  useEffect(() => {
    setLoading(true);
    getMatchingUser(username).then((result) => {
      setUser(result);
      changeMambas(getUserGuess(result.guess24) || ALL_MAMBAS);
      setLoading(false);
    });
  }, [username]);

  useEffect(() => {
    setShowTooltip(false);
    setStatus("Leverer...");
  }, [mambas]);

  // if showTooltip is true wait 5 seconds and then set it to false
  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => {
        setShowTooltip(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showTooltip]);

  const submitGuess = () => {
    setShowTooltip(true);
    const mappedGuess = mapGuess(mambas);
    makeGuess(username, mappedGuess)
      .then((res) => {
        setStatus("Din profesi har blitt levert.");
      })
      .catch((error) => {
        console.log(error);
        setStatus("En feil har skjedd, prøv igjen...");
      });
  };

  return (
    <Stack
      sx={{
        paddingTop: "1rem",
        paddingBottom: "5rem",
      }}
      alignItems="center"
    >
      {!loading ? (
        <>
          <Stack alignItems="center">
            <h3>Velkommen {user ? user.name : username}!</h3>
            <h3>Hvem vinner Mambas 2024?</h3>
            <p style={{ maxWidth: "800px" }}>
              <b>Regler: </b>
              Alle deltakerene skal rangeres fra 1. plass til 16. plass slik du
              tror årets Mambas kommer til å gå. Poengene regnes ut slik: Riktig
              plassert golfer gir 0p Bom med en plassering gir 1p (den du tippet
              på 5. kommer på 4. eller 6.) Bom med to plasseringer gir 2p (den
              du tippet på 5. kommer på 3. eller 7.) Samlet poengsum blir
              kalkulert og siden det tross alt er golf det handler om så er
              vinneren den med lavest poengsum! Resultatet fra Mambas blir tatt
              i rekkefølgen som blir den endelige i Golf GameBook. Hvis noen
              ikke stiller til start blir personen fjernet fra ditt gjett og
              plassen under tar plassen til personen med DNS (did not start).
            </p>
          </Stack>
          <Stack sx={{ paddingBottom: "2rem" }} justifyContent="center">
            <Button
              variant="contained"
              style={{ maxWidth: "200px" }}
              size="lg"
              onClick={submitGuess}
            >
              Lever ditt gjett
            </Button>
            {showTooltip && <p>{status}</p>}
          </Stack>
          <Stack>
            <MambaListApp mambasProphet={mambas} onChange={changeMambas} />
          </Stack>
        </>
      ) : (
        <Stack className="justify-content-center">
          <CircularProgress />
        </Stack>
      )}
    </Stack>
  );
};
