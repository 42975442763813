// import { Tabs, Tab, Stack } from "@mui/material";
import lugg from "./Lugg.jpg";
import lasse from "./Lasse.jpg";
import kriss from "./Kriss.jpeg";
import ge from "./ge.JPG";
import olav from "./olav.jpeg";
import { Grid, Stack, Box } from "@mui/material";

export const Champions = () => {
  return (
    <Stack sx={{ maxWidth: "1200px" }} alignItems="center" spacing={2} p={2}>
      {/* <Stack direction="Stack direction="row"" justifyContent="center" alignItems="center">
        <Tabs value="champions" onChange={() => console.log("change")}>
          <Tab label="Champions" value="champions" />
          <Tab label="Chairmen" value="chairmen" />
        </Tabs>
      </Stack"row" style={{ padding: "2rem" }}>
        <hr />
      </Stack> */}
      <Grid container spacing={2} direction="row" alignItems="center" p={1}>
        <Grid item xs={12} sm={5}>
          <img src={olav} width="300px" />
        </Grid>
        <Grid item xs={12} sm={7}>
          <h2> Olav "Colav" Røed Meberg</h2>
          <h4>Mambas Champion 2024</h4>
          <p className="display-linebreak">
            Olav Meberg, som nå virkelig har levd opp til sitt tidligere
            kallenavn Ben Curtis. Han var ranket som mot bunnen (13/18) i power
            rankingen 2024, men viste at han ikke er en spiller som skal
            undervurderes. Han spilte med egne ord sin "livs beste runde" og tok
            med seg seieren i Mambas Invitational 2024 etter en lang og jevn
            runde på Asker GK. Vil Olav klare å holde momentumet oppe og levere
            like sterkt under Mambas Winter Cup 2025, eller vil han choke som en
            ekte Mamba? En ting er sikkert - Olav har bevist at han er en
            spiller å regne med i Mambas Invitational og gjorde virkelig jobben
            for å hindre at the Hås lurte til seg pokalen i år. Kanskje neste år
            er hestens år i mambaskalenderen? 🐴
            {" \n"}
            {" \n"}
            <b>Favoritt kølle:</b> 5-Jern
            {" \n"}
            <b>Mål for mambas:</b> Å eksponentielt øke fellesen for hvert år med
            mer morro og masse merch gjennom deltagelse i Mambas Apparel Group
            AS.
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction="row-reverse"
        alignItems="center"
        p={1}
      >
        <Grid item xs={12} sm={5}>
          <img src={ge} width="300px" />
        </Grid>
        <Grid item xs={12} sm={7} direction="column" justifyContent="center">
          <Box>
            <h2> Gustav Emil "Gregers" Dietrichson </h2>
            <h4>Mambas Champion 2023</h4>
            <p className="display-linebreak">
              Gustav Emil Dietrichson har vist at selv en av de ferskeste
              golfspillerne har det som trengs for å vinne Mambas Invitational.
              Han har en sterk mentalitet og en god evne til å holde hodet kaldt
              under press. Han kapret også seieren fra en av de mest erfarne
              spillerne i Mambas, Andreas Horstad. Gregers tok ikke bare med seg
              tittelen som Mambas Champion og den grønne jakken. Han tok også
              med seg en ny tittel som Chairman of the Board, og han er en del
              av Mambas sin delegasjon som reiser for å heie frem Viktor Hovland
              i Ryder Cup 2023.
              {" \n"}
              {" \n"}
              <b>Favoritt kølle:</b> 3-wood
              {" \n"}
              <b>Mål for mambas:</b> Å føre Mambas inn i en ny gullalder ved
              hjelp av vilje, vold og Vaselin. Samt å gi håp og inspirere alle
              med genfeil at også de kan oppnå storhet i livet sitt.
            </p>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row" alignItems="center" p={1}>
        <Grid item xs={12} sm={5}>
          <img src={kriss} width="300px" />
        </Grid>
        <Grid item xs={12} sm={7}>
          <h2> Kristoffer Winge </h2>
          <h4>Mambas Champion 2022</h4>
          <p className="display-linebreak">
            Kristoffer Winge, kom inn i sesongen 2022 med en sterk mental kraft
            og nye custom fitte køller. Etter en 11-plass i 2021 og en 10-plass
            på powerrankingen, viste han virkelig at han har mentaliteten til en
            toppscorer. Etter seieren skal Kristoffer reise USA for å realisere
            drømmen om en master i bizniz, og runder på samtlige høyt rangerte
            baner i Nord-Amerika. Kanskje det til og med blir en Norgesblazer å
            se på selveste Augusta National i 2023?
            {" \n"}
            {" \n"}
            <b>Favoritt kølle:</b> 6 Jernet
            {" \n"}
            <b>Mål for mambas:</b> Polere pokalen!
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction="row-reverse"
        alignItems="center"
        p={1}
      >
        <Grid item xs={12} sm={5}>
          <img src={lugg} width="300px" />
        </Grid>
        <Grid item xs={12} sm={7}>
          <h2> Fredrik "Lugg" Lugg </h2>
          <h4>Mambas Champion 2021</h4>
          <h4>Mambas Winter Cup Champion 2023</h4>
          <p className="display-linebreak">
            Fredrik Lugg, er Mambas Invitational sin andre vinner. Han gjorde en
            imponerende snu operasjon etter å ha kommet på sisteplass i 2020,
            til å vinne Mambas i 2021. Gjennom dette viste han at han en at du
            aldri skal undervurdere en taper med Mamba Mentality™ og store mål.
            {" \n"}
            {" \n"}
            <b>Favoritt kølle:</b> Driver
            {" \n"}
            <b>Mål for mambas:</b> Mambas sin første double champion + Longest
            drive 250m+
          </p>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row" alignItems="center" p={1}>
        <Grid item xs={12} sm={5}>
          <img src={lasse} width="300px" />
        </Grid>
        <Grid item xs={12} sm={7}>
          <h2> Lasse "Lazy" Killingmo </h2>
          <h4>Mambas Champion 2020</h4>
          <p className="display-linebreak">
            Lasse Killingmo vil gå inn i historiebøkene som Mambas Invitational
            sin aller første vinner. Lasse beskriver selv dagen og den
            påfølgende kvelden som en av de desidert beste i hele hans liv. Med
            sin over middels interesse i golf mener Lasse selv at skillza ikke
            står i stil med interessen, men de holdt likevel til seier i 2020.
            {"\n"}
            {" \n"}
            <b>Favoritt kølle:</b> Chipper
            {"\n"}
            <b>Mål for mambas:</b> Sørge for at Andreas Horstad ikke vinner /
            vinne banketten
          </p>
        </Grid>
      </Grid>
      <Box>
        <hr />
        <h3>
          <b>Andre bragder:</b>
        </h3>
        <h4>
          <b>
            <u>2024</u>
          </b>
        </h4>
        <p>
          <b>Longest drive:</b> Fredrik Lugg
        </p>
        <p>
          <b>Closest to pin:</b> Eirik Bergsjø
        </p>
        <p>
          <b>Mambas winter cup:</b> Eirik Bergsjø
        </p>
        <p>
          <b>Mambas scramble:</b>
        </p>
        <h4>
          <b>
            <u>2023</u>
          </b>
        </h4>
        <p>
          <b>Mambas scramble:</b> The Foreskins (Andreas Horstad & Eirik
          Bergsjø)
        </p>
        <p>
          <b>Mambas winter cup:</b> Fredrik Lugg
        </p>
        <p>
          <b>Longest drive:</b> Eirik Lunde
        </p>
        <p>
          <b>Closest to pin:</b> Peter William Skjensvold
        </p>
        <h4>
          <b>
            <u>2022</u>
          </b>
        </h4>
        <p>
          <b>Longest drive:</b> Andreas Horstad
        </p>
        <p>
          <b>Closest to pin:</b> Andreas Horstad
        </p>
        <h4>
          <b>
            <u>2021</u>
          </b>
        </h4>
        <p>
          <b>Longest drive:</b> Fredrik Mellbye
        </p>
        <p>
          <b>Closest to pin:</b> Gustav Emil Dietrichson
        </p>
        <h4>
          <b>
            <u>2020</u>
          </b>
        </h4>
        <p>
          <b>Longest 4-jern:</b> Thomas Malm
        </p>
        <p>
          <b>Closest to pin:</b> Eirik Lunde
        </p>
      </Box>
    </Stack>
  );
};
