import { ALL_MAMBAS } from "./constants";

export const mapGuess = (mambas) => {
  const nameMap = mambas.map((mamba) => {
    return mamba.name;
  });
  return nameMap.reduce((k, v, i) => ({ ...k, [i + 1]: v }), {});
};

export const getUserGuess = (userGuess) => {
  if (userGuess) {
    const test = ALL_MAMBAS.map((test, i) => {
      return { ...test, name: userGuess[i + 1] };
    });
    return test;
  }
};
