import { useEffect, useState } from "react";
import { useMambas } from "../contexts/MambasContext";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/system";
import { HcpChart } from "./HcpChart";
import {
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const StyledData = styled.div`
  text-decoration: none;
  padding: 0.5rem 1rem;
  min-width: 100px;
  min-height: 100px;
  background-color: #ebecf0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  text-align: center;
`;

export const Mambas = () => {
  const [open, setOpen] = useState(false);
  const [selectedMamba, setSelectedMamba] = useState();
  const [mambas, setMambas] = useState(undefined);
  const { mambasStatistics } = useMambas();

  //get mambas and sort by lowest hcp, if no hcp is found place at bottom

  const handleClickOpen = (name) => {
    // setOpen(true);
    // setSelectedMamba(mambas.find((i) => i.name === name));
    setMambas(
      mambasStatistics.toSorted((a, b) => {
        if (a.statistics?.[0]?.hcp === undefined) {
          return 1;
        }
        return a.statistics?.[0]?.hcp > b.statistics[0]?.hcp ? 1 : -1;
      })
    );
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedMamba(undefined);
  };
  return (
    <Container mb={5}>
      <Stack direction="row" gap={2} justifyContent="center" mb={5}>
        <TableContainer
          component={Paper}
          sx={{ minWidth: "200px", maxWidth: "700px" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Mambas HCP Ranking</b>
                </TableCell>
                <TableCell align="left">
                  <b>Handicap</b>
                </TableCell>
                <TableCell align="center">
                  <b>Klubb</b>
                </TableCell>
              </TableRow>
            </TableHead>
            {mambasStatistics === null && <CircularProgress />}
            <TableBody>
              {mambasStatistics &&
                mambasStatistics?.map((mamba) => {
                  return (
                    <TableRow
                      key={mamba?.name}
                      sx={{
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "#e0e0e0" },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <b>{mamba?.name}</b>
                      </TableCell>
                      <TableCell align="left">
                        {mamba?.stats?.[0]?.hcp || "Ingen hcp funnet"}
                      </TableCell>
                      <TableCell align="center">
                        {mamba?.stats?.[0]?.club ?? "Ingen klubb funnet"}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {selectedMamba && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="mamaba-dialog-title"
          aria-describedby="mamba-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="mamba-dialog-title">
            {selectedMamba?.name}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="mamba-dialog-description">
              <Stack direction="row" gap={2} justifyContent="space-evenly">
                <StyledData>
                  <p>
                    <b>HCP:</b>
                  </p>
                  <p>{selectedMamba && selectedMamba?.statistics[0]?.hcp}</p>
                </StyledData>
                <StyledData>
                  <p>
                    <b>Klubb:</b>
                  </p>
                  <p>{selectedMamba && selectedMamba?.statistics[0]?.club}</p>
                </StyledData>
              </Stack>
              <Stack>
                <HcpChart data={selectedMamba?.statistics}></HcpChart>
              </Stack>
              <p>
                Data hentet:{" "}
                {new Date(
                  selectedMamba?.statistics?.[0]?.date
                ).toLocaleDateString()}
              </p>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </Container>
  );
};
