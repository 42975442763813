import logo from "./logo_white.png";
import "./App.css";
import {
  Stack,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Library } from "./LibraryPage";
import styled from "styled-components";
import { Champions } from "./Champions";
import { MainPage } from "./MainPage";
import { Profeten } from "./Profeten";
import { SignInPage } from "./SignInPage";
import { Mambas } from "./Mambas";
import { Scramble } from "./ScramblePage";
import { MambasContextProvider } from "./contexts/MambasContext";
import SecretSanta from "./SecretSanta";
import WintercupPage from "./wintercup";
import AdminPage from "./admin";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";

function ResponsiveMenu() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const linkMap = {
    CHAMPIONS: "/champions",
    BILDER: "/bilder",
    MAMBAS: "/mambas",
    PROFETEN: "/profeten/login",
    "SECRET SANTA": "/secretsanta",
  };

  const drawer = (
    <Stack
      alignItems="center"
      sx={{ height: "80vh", backgroundColor: "#076652", textAlign: "center" }}
    >
      <List sx={{ height: "80vh" }}>
        <Link to="/" onClick={handleDrawerToggle}>
          <img
            src={logo}
            width="200em"
            alt="Mambas logo"
            style={{ cursor: "pointer" }}
          />
        </Link>
        {Object.keys(linkMap).map((text) => (
          <ListItem key={text}>
            <ListItemButton sx={{ width: "100%", textAlign: "center" }}>
              <StyledLink to={linkMap[text]} onClick={handleDrawerToggle}>
                <ListItemText primary={text} sx={{ textAlign: "center" }} />
              </StyledLink>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );

  return (
    <div>
      <IconButton color="inherit" onClick={handleDrawerToggle}>
        <MenuIcon size="large" />
      </IconButton>
      <Drawer
        anchor="top"
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        {drawer}
      </Drawer>
    </div>
  );
}

const StyledLink = styled(Link)`
  color: #f2f2f2;
  text-decoration: none;
  &:hover {
    color: black;
  }
`;

const App = () => {
  return (
    <div className="App">
      <MambasContextProvider>
        <BrowserRouter>
          <Stack spacing={2} direction="column" alignItems="center">
            <div className="mobile-menu">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ paddingLeft: 5 }}
              >
                <Link to="/">
                  <img
                    fluid
                    src={logo}
                    width="200em"
                    alt="Mambas logo"
                    style={{ cursor: "pointer" }}
                  />
                </Link>
                <ResponsiveMenu />
              </Stack>
            </div>
            <div className="desktop-menu">
              <Link to="/">
                <img
                  fluid
                  src={logo}
                  width="200em"
                  alt="Mambas logo"
                  style={{ cursor: "pointer" }}
                />
              </Link>
              <Stack direction="row" spacing={2}>
                <StyledLink to="/champions">CHAMPIONS</StyledLink>
                {/* <StyledLink to="/wintercup">WINTERCUP</StyledLink> */}
                {/* 
            <StyledLink to="/scramble">SCRAMBLE</StyledLink>
            */}

                <StyledLink to="/secretsanta">SECRET SANTA</StyledLink>
                <StyledLink to="/bilder">BILDER</StyledLink>
                <StyledLink to="/mambas">MAMBAS</StyledLink>

                <Tooltip
                  id="link-tooltip"
                  title="Profeten er tilbake neste år..."
                >
                  {/* <StyledLink to="/profeten/login">PROFETEN</StyledLink> */}
                  {/* Diable click */}
                  <StyledLink
                    to="#"
                    style={{
                      cursor: "default",
                      color: "gray",
                    }}
                  >
                    PROFETEN
                  </StyledLink>
                </Tooltip>
              </Stack>
            </div>

            <Routes>
              <Route exact path="/" element={<MainPage />} />
              <Route path="/bilder" element={<Library />} />
              <Route path="/champions" element={<Champions />} />
              <Route path="/mambas" element={<Mambas />} />
              <Route path="/scramble" element={<Scramble />} />
              <Route path="/secretsanta" element={<SecretSanta />} />
              <Route path="/wintercup" element={<WintercupPage />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/profeten/login" element={<SignInPage />} />
              <Route path="/profeten/:username" element={<Profeten />} />
              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>DENNE SIDEN ER MULIGENS UNDER ARBEID</p>
                  </main>
                }
              />
            </Routes>
          </Stack>
        </BrowserRouter>
      </MambasContextProvider>
    </div>
  );
};

export default App;
