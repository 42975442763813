import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";

const TEAMS = {
  teamOne: {
    name: "Krigfattig",
    teamHcp: 0,
    players: [
      {
        name: "Fredrik Lugg",
        hcp: 20,
      },
      {
        name: "Fredrik Mellbye",
        hcp: 17,
      },
    ],
    roundOneWin: true,
    roundOneScore: 6,
  },
  teamTwo: {
    name: "The Fore-skins",
    teamHcp: 0,
    players: [
      {
        name: "Andreas Horstad",
        hcp: 0,
      },
      {
        name: "Eirik Bergstein",
        hcp: 0,
      },
    ],
    roundOneWin: true,
    roundOneScore: 4,
  },
  teamThree: {
    name: "The FehwayFindahs",
    teamHcp: 0,
    players: [
      {
        name: "Gustav Emil Dietrichson",
        hcp: 0,
      },
      {
        name: "Eirik Lunde",
        hcp: 0,
      },
    ],
    roundOneWin: true,
    roundOneScore: "Walkover",
  },
  teamFour: {
    name: "Politi og Røver",
    teamHcp: 0,
    players: [
      {
        name: "Olav Meberg",
        hcp: 0,
      },
      {
        name: "Erik Theodor Torgersen",
        hcp: 0,
      },
    ],
    roundOneScore: 2,
  },
  teamFive: {
    name: "Golf on the spectrum",
    teamHcp: 0,
    players: [
      {
        name: "Mathias Klev",
        hcp: 0,
      },
      {
        name: "Peter William Skjensvold",
        hcp: 0,
      },
    ],
    roundOneWin: true,
    roundOneScore: 1,
  },
  teamSix: {
    name: "Herman & Carl",
    teamHcp: 0,
    players: [
      {
        name: "Herman Rosell",
        hcp: 0,
      },
      {
        name: "Carl Oscar Northug",
        hcp: 0,
      },
    ],
    roundOneScore: "No show",
  },
  teamSeven: {
    name: "Slicinators",
    teamHcp: 0,
    players: [
      {
        name: "Lars Folkestad",
        hcp: 0,
      },
      {
        name: "Lasse Killingmo",
        hcp: 0,
      },
    ],
    roundOneWin: true,
    roundOneScore: 0,
  },
  teamEight: {
    name: "Thomas & Trym",
    teamHcp: 0,
    players: [
      {
        name: "Thomas Malm",
        hcp: 0,
      },
      {
        name: "Trym Moe",
        hcp: 0,
      },
    ],
    roundOneScore: 4,
  },
};

/**
Scramble 2023:
Klev og Pw
Ge og Lunde
Lugg og Mellbye
Lars og Lazy
Herman og Carl
Olav og Erik
Bergsjo og Andy
Thomas og Trym
 */

const ScrambleCardRoundOne = ({ teamOne, teamTwo, round }) => {
  return (
    <Card px={50}>
      <CardContent>
        {teamOne?.roundOneWin ? (
          <>
            <Typography variant="body1" noWrap>
              <b>Vinner: {teamOne.name}</b>
            </Typography>
            <Typography variant="body1" noWrap>
              <b>Score: {teamOne?.roundOneScore}</b>
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body1" noWrap>
              <b>{teamOne.name}</b>
            </Typography>
            <Typography variant="body1" noWrap>
              {teamOne?.roundOneScore && "Score: " + teamOne?.roundOneScore}
            </Typography>
          </>
        )}
        {teamOne?.players?.map((player) => (
          <>
            <Typography variant="body2" key={player.name}>
              {player.name}
            </Typography>
          </>
        ))}
        <Divider />
        {teamTwo?.roundOneWin ? (
          <>
            <Typography variant="body1" noWrap>
              <b>Vinner: {teamTwo.name}</b>
            </Typography>
            <Typography variant="body1" noWrap>
              <b>Score: {teamTwo?.roundOneScore}</b>
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body1" noWrap>
              <b>{teamTwo.name}</b>
            </Typography>
            <Typography variant="body1" noWrap>
              <b>
                {teamTwo?.roundOneScore && "Score: " + teamTwo?.roundOneScore}
              </b>
            </Typography>
          </>
        )}
        {teamTwo?.players?.map((player) => (
          <Typography variant="body2" key={player.name}>
            {player.name}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
};
const ScrambleCardRoundTwo = ({ teamOne, teamTwo, round }) => {
  return (
    <Card px={50}>
      <CardContent>
        <Typography variant="body1" noWrap>
          <b>{teamOne.name}</b>
        </Typography>
        {teamOne?.players?.map((player) => (
          <Typography variant="body2" key={player.name}>
            {player.name}
          </Typography>
        ))}
        <Divider />
        <Typography variant="body1" noWrap>
          <b>{teamTwo.name}</b>
        </Typography>
        {teamTwo?.players?.map((player) => (
          <Typography variant="body2" key={player.name}>
            {player.name}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
};

export const Scramble = () => {
  const {
    teamOne,
    teamTwo,
    teamThree,
    teamFour,
    teamFive,
    teamSix,
    teamSeven,
    teamEight,
  } = TEAMS;

  return (
    <Grid container p={5}>
      <Grid item xs={12} spacing={2}>
        <h1>Mambas Scramble</h1>
        <p>
          Mambas scramble siden fungerer best på stor skjerm, fungerer kanskje
          dårlig på telefon.
        </p>
        <Grid xs={12} item direction="row" spacing={2} alignItems="center">
          <h2>Runde 1</h2>
          <Grid
            container
            item
            direction="row"
            xs={12}
            gap={2}
            justifyContent="center"
          >
            <Grid item xs={12} md={5} spacing={2}>
              <ScrambleCardRoundOne teamOne={teamOne} teamTwo={teamEight} />
            </Grid>
            <Grid item xs={12} md={5} spacing={2}>
              <ScrambleCardRoundOne teamOne={teamSeven} teamTwo={teamFive} />
            </Grid>
            <Grid item xs={12} md={5} spacing={2}>
              <ScrambleCardRoundOne teamOne={teamTwo} teamTwo={teamFour} />
            </Grid>
            <Grid item xs={12} md={5} spacing={2}>
              <ScrambleCardRoundOne teamOne={teamThree} teamTwo={teamSix} />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item direction="row" spacing={2} alignItems="center">
          <h2>Runde 2</h2>
          <Grid
            container
            item
            direction="row"
            xs={12}
            gap={2}
            justifyContent="center"
          >
            <Grid item xs={12} md={5} spacing={2}>
              <ScrambleCardRoundTwo teamOne={teamOne} teamTwo={teamTwo} />
            </Grid>
            <Grid item xs={12} md={5} spacing={2}>
              <ScrambleCardRoundTwo teamOne={teamThree} teamTwo={teamFive} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
