const results = {
  "Lasse Killingo": {
    round1: {
      score: -12,
      strokes: null,
      points: 2000,
    },
    round2: {
      score: -21,
      strokes: 88,
      points: 2000,
    },
    round3: {
      score: -8,
      strokes: 94,
      points: 1500,
    },
  },
  "Fredrik Lugg": {
    round1: {
      score: -11,
      strokes: 81,
      points: 1500,
    },
    round2: {
      score: -13,
      strokes: 84,
      points: 1500,
    },
    round3: {
      score: -5,
      strokes: 80,
      points: 1100,
    },
  },
  "Andreas Killi Horstad": {
    round1: {
      score: -7,
      strokes: 77,
      points: 1200,
    },
    round2: {
      score: -12,
      strokes: 76,
      points: 1200,
    },
    round3: {
      score: -2,
      strokes: 85,
      points: 650,
    },
  },
  "Fredrik Mellbye": {
    round1: {
      score: -5,
      strokes: 84,
      points: 900,
    },
    round2: {
      score: -4,
      strokes: 92,
      points: 650,
    },
    round3: {
      score: -2,
      strokes: 84,
      points: 650,
    },
  },
  "Peter William Skjensvold": {
    round1: {
      score: -5,
      strokes: 101,
      points: 900,
    },
    round2: {
      score: null,
      strokes: null,
      points: 0,
    },
    round3: {
      score: null,
      strokes: null,
      points: 0,
    },
  },
  "Eirik Bergsjø": {
    round1: {
      score: -5,
      strokes: 85,
      points: 900,
    },
    round2: {
      score: -10,
      strokes: 85,
      points: 850,
    },
    round3: {
      score: -11,
      strokes: 77,
      points: 2000,
    },
  },
  "Mathias Klev": {
    round1: {
      score: -2,
      strokes: 94,
      points: 650,
    },
    round2: {
      score: -10,
      strokes: 88,
      points: 850,
    },
    round3: {
      score: 11,
      strokes: 105,
      points: 200,
    },
  },
  "Trym Frafjord Moe": {
    round1: {
      score: -2,
      strokes: 95,
      points: 650,
    },
    round2: {
      score: 5,
      strokes: 107,
      points: 400,
    },
    round3: {
      score: -5,
      strokes: 89,
      points: 1100,
    },
  },
  "Eirik Lunde": {
    round1: {
      score: -1,
      strokes: 94,
      points: 450,
    },
    round2: {
      score: 9,
      strokes: 110,
      points: 200,
    },
    round3: {
      score: 7,
      strokes: 100,
      points: 300,
    },
  },
  "Gustav Emil Dietrichson": {
    round1: {
      score: -1,
      strokes: 99,
      points: 450,
    },
    round2: {
      score: -2,
      strokes: 104,
      points: 500,
    },
    round3: {
      score: -4,
      strokes: 93,
      points: 900,
    },
  },
  "Carl Oscar Northug": {
    round1: {
      score: 2,
      strokes: null,
      points: 300,
    },
    round2: {
      score: -4,
      strokes: 100,
      points: 650,
    },
    round3: {
      score: 2,
      strokes: 98,
      points: 400,
    },
  },
  "Herman Rosell-Helland": {
    round1: {
      score: 11,
      strokes: 109,
      points: 200,
    },
    round2: {
      score: null,
      strokes: null,
      points: 0,
    },
    round3: {
      score: null,
      strokes: null,
      points: 0,
    },
  },
  "Lars Christian Folkestad": {
    round1: {
      score: 51,
      strokes: null,
      points: 100,
    },
    round2: {
      score: 17,
      strokes: 125,
      points: 100,
    },
    round3: {
      score: null,
      strokes: null,
      points: 0,
    },
  },
  "Olav Meberg": {
    round1: {
      score: null,
      strokes: null,
      points: 0,
    },
    round2: {
      score: -11,
      strokes: 110,
      points: 1000,
    },
    round3: {
      score: -3,
      strokes: 105,
      points: 800,
    },
  },
  "Thomas Sand Malm": {
    round1: {
      score: null,
      strokes: null,
      points: 0,
    },
    round2: {
      score: 7,
      strokes: 124,
      points: 300,
    },
    round3: {
      score: 1,
      strokes: 104,
      points: 500,
    },
  },
};
export const formattedResults = Object.entries(results).map(
  ([name, rounds]) => {
    const totalScore = Object.values(rounds).reduce(
      (sum, round) => sum + round.score,
      0
    );
    const totalStrokes = Object.values(rounds).reduce(
      (sum, round) => sum + (round.strokes || 0),
      0
    );
    const totalPoints = Object.values(rounds).reduce(
      (sum, round) => sum + round.points,
      0
    );
    // between 0 and 1000000000
    const randomId = Math.floor(Math.random() * 1000000000);

    return {
      id: randomId,
      name,
      totalPoints,
      totalScore,
      totalStrokes,
      rounds,
    };
  }
);
