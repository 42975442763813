import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { formattedResults } from "./results/results";

const WinterCupTable = () => {
  // firstly sort on total points and then on total strokes
  const data = formattedResults.sort((a, b) => {
    if (a.totalPoints < b.totalPoints) {
      return 1;
    } else if (a.totalPoints > b.totalPoints) {
      return -1;
    } else {
      if (a.totalStrokes < b.totalStrokes) {
        return -1;
      } else if (a.totalStrokes > b.totalStrokes) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  const formatTotalStrokes = (totalPoints) => {
    if (totalPoints === -1) {
      return "n/a";
    } else {
      return totalPoints;
    }
  };

  return (
    <TableContainer component={Paper} sx={{ maxWidth: "90%" }}>
      <Table sx={{ overflowX: "auto" }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Navn</b>
            </TableCell>
            <TableCell>
              <b>Poeng</b>
            </TableCell>
            <TableCell>
              <b>Totalt antall slag</b>
            </TableCell>
            <TableCell>
              <b>Score</b>
            </TableCell>
            <TableCell>
              <b>Runde 1</b>
            </TableCell>
            <TableCell>
              <b>Runde 2</b>
            </TableCell>
            <TableCell>
              <b>Runde 3</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.totalPoints}</TableCell>
              <TableCell>{formatTotalStrokes(row.totalStrokes)}</TableCell>
              <TableCell>{row.totalScore}</TableCell>
              {row.rounds["round1"].score ? (
                <TableCell>{row.rounds["round1"].score}</TableCell>
              ) : (
                <TableCell>-</TableCell>
              )}
              {row.rounds["round2"].score ? (
                <TableCell>{row.rounds["round2"].score}</TableCell>
              ) : (
                <TableCell>-</TableCell>
              )}
              {row.rounds["round3"].score ? (
                <TableCell>{row.rounds["round3"].score}</TableCell>
              ) : (
                <TableCell>-</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WinterCupTable;
