import { Stack, TextField, Button } from "@mui/material";
import React, { useState } from "react";

const AdminPage = () => {
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  const handleSignIn = () => {
    // Perform authentication logic here
    // For simplicity, let's assume the username is "admin" and password is "password"
    if (password === "admin123") {
      setLoggedIn(true);
    } else {
      alert("Invalid credentials");
    }
  };

  return (
    <div>
      {loggedIn ? (
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <h1>Welcome, Admin!</h1>
          <h2>Here you can edit the website</h2>
        </Stack>
      ) : (
        <Stack spacing={2}>
          <TextField
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ width: "300px", backgroundColor: "#f2f2f2" }}
          />
          <Button
            variant="contained"
            onClick={handleSignIn}
            sx={{ width: "300px" }}
          >
            Sign In
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default AdminPage;
