import { Stack } from "@mui/material";
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

export const HcpChart = ({ data }) => {
  return (
    <Stack width="100%" height={500} p={2}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data
            .map((d) => {
              return { ...d, hcp: parseFloat(d?.hcp.replace(",", ".")) };
            })
            .sort((a, b) => new Date(a.date) - new Date(b.date))}
          margin={{
            top: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            height={70}
            tickFormatter={(value) => {
              if (!value) return "";
              return new Date(value?.split("T")[0]).toLocaleDateString(
                "nb-NO",
                {
                  month: "short",
                  day: "numeric",
                  year: "2-digit",
                }
              );
            }}
          />
          <YAxis
            interval={0}
            tickCount={10}
            domain={["dataMin - 5", "dataMax + 5"]}
          />
          <Tooltip
            labelFormatter={(label) => {
              if (!label) return "";
              return new Date(label?.split("T")[0]).toLocaleDateString(
                "nb-NO",
                {
                  month: "short",
                  day: "numeric",
                  year: "2-digit",
                }
              );
            }}
          />
          <Line
            type="monotone"
            dataKey="hcp"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Stack>
  );
};
