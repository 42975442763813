import { Box, Stack } from "@mui/material";
import mambas2021 from "./IMG_6906.JPG";
import mambas2020 from "./mambas2020.jpeg";
import mambas2022 from "./mambas2022.jpeg";
import mambas2023 from "./mambas2023.jpeg";

export const Library = () => {
  return (
    <Stack>
      <Box sx={{ padding: 2 }}>
        <h3>Mambas Invitational 2023</h3>
        <img src={mambas2023} width="800px" fluid></img>
      </Box>

      <Box sx={{ padding: 2 }}>
        <h3>Mambas Invitational 2022</h3>
        <img src={mambas2022} width="800px" fluid></img>
      </Box>

      <Box sx={{ padding: 2 }}>
        <h3>Mambas Invitational 2021</h3>
        <img src={mambas2021} width="800px" fluid></img>
      </Box>

      <Box sx={{ padding: 2 }}>
        <h3>Mambas Invitational 2020</h3>
        <img src={mambas2020} width="800px" fluid></img>
      </Box>
    </Stack>
  );
};
